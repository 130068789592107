import React, { useContext, useState } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Button, Row, Form, Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import * as Yup from "yup";

const updateSchema = Yup.object().shape({
    id: Yup.string().required("Video ID is required"),
    title: Yup.string().required("Title is required"),
});

const AddPublicVideo = ({ onVideoAdded }) => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [showToast, setShowToast] = useState(false);

    const initialValues = {
        id: "",
        title: "PPAI Expo Follow Up",
    };

    const updateSubmitHandler = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await sendRequest(
                "/api/videos/upload",
                "POST",
                JSON.stringify(values),
                {
                    "Content-Type": "application/json",
                },
                authContext.token
            );
            if (response.status === 201 || response.status === 200) {
                //successful update, show success message / update page
                resetForm();
                setShowToast(true);
                onVideoAdded();
            }
        } catch (err) {
            console.error('Error adding public video:', err);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
        <ToastError show={!!error} onClose={clearError} message={error} />
        <Card>
            <Card.Body>
                <Card.Title>Add Public Video</Card.Title>
                <Formik
                    initialValues={initialValues}
                    validationSchema={updateSchema}
                    onSubmit={updateSubmitHandler}
                >
                    {({ isSubmitting }) => (
                        <FormikForm>
                            <Form.Group as={Row} controlId="formHorizontalVideoId">
                                <Form.Label column sm={2}>
                                    Video ID
                                </Form.Label>
                                <Field
                                    as={Form.Control}
                                    type="text"
                                    name="id"
                                    placeholder="Video ID"
                                />
                                <ErrorMessage
                                    name="id"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </Form.Group>

                            <Form.Group as={Row} controlId="formHorizontalTitle">
                                <Form.Label column sm={2}>
                                    Title
                                </Form.Label>
                                <Field
                                    as={Form.Control}
                                    type="text"
                                    name="title"
                                    placeholder="Title"
                                />
                                <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isLoading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    "Add Video"
                                )}
                            </Button>
                        </FormikForm>
                    )}
                </Formik>
            </Card.Body>
        </Card>
        <ToastError
            show={showToast}
            setShow={setShowToast}
            message="Video added successfully"
        />
    </React.Fragment>
    );
}

export default AddPublicVideo;
        