import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../shared/hooks/http-hook";

const PublicVideoPlayer = () => {
	const { videoId } = useParams();
	const { sendRequest } = useHttpClient();
	const [videoDetails, setVideoDetails] = useState(null);

	useEffect(() => {
		const fetchVideoDetails = async () => {
			if (videoId) {
				try {
					const response = await sendRequest(
						`/api/videos/${videoId}/public`,
						"GET",
						null,
						{}
					);
					setVideoDetails(response.data);
				} catch (err) {
					console.error("Error fetching video details:", err);
				}
			}
		};

		fetchVideoDetails();
	}, [videoId, sendRequest]);

	return (
		<div
			style={{
				minHeight: "100vh",
				backgroundColor: "#1c004b",
				margin: 0,
				padding: 0,
			}}
		>
			<div
				className="container"
				style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px 20px", }}
			>
				<a href="https://ratava.com">
					<img
						src="/logo-white.png"
						alt="logo"
						style={{
							width: "50%",
							padding: "20px 0"
						}}
					/>
				</a>
				<button
      style={{
        backgroundColor: "#ffffff",
        border: "none",
        color: "#1c004b",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "4px",
      }}
      onClick={() => {
        window.location.href = "https://ratava.com/";
      }}
    >
      Learn More
    </button>
			</div>
			{videoDetails ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "white",
					}}
				>
					<video
						width="70%"
						height="auto"
						controls
						src={videoDetails.video_url}
					/>
				</div>
			) : (
				<p style={{ color: "white" }}>Video not found</p>
			)}
		</div>
	);
};

export default PublicVideoPlayer;
